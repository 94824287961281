.inputfield-root {
  @apply z-auto;
}
.smooth-select--control {
  @apply cursor-pointer bg-white ring-4 py-4 px-6 ring-gray-200/20 rounded-xl transition-all h-14 text-right content-end justify-end;
}

.smooth-select-value-container {
  @apply cursor-pointer flex flex-row-reverse items-center justify-end;
}

.smooth-select-menu {
  @apply z-10 cursor-pointer rounded-b-2xl  bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-700;
}

.smooth-select-option {
  @apply cursor-pointer !important;
  @apply text-gray-900 h-14 hover:bg-sky-700 hover:text-white select-none relative py-2 pl-4 pr-4 dark:text-gray-100;
}
.smooth-select-option--active {
  @apply bg-sky-700 text-white;
}

.smooth-select--label {
  @apply absolute top-4 left-5;
}
