.swiper {
    @apply w-full h-full;
}

.swiper-slide {
    @apply text-center text-white text-sm flex justify-center items-center;
}

.swiper-card img {
    @apply  block w-full h-full pb-6 pt-4 px-6;
}

