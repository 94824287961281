@tailwind base;
@tailwind components;
@tailwind utilities;

/* start: copied from fintech-ui */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-main {
  @apply flex flex-col items-center justify-center w-full h-full p-4 bg-white rounded-lg shadow-lg;
}

.continue-button {
  @apply bg-primary-blue disabled:bg-gray-400 text-white py-3 font-bold flex-1 rounded-lg hover:bg-black disabled:pointer-events-none;
}

.select-button {
  @apply bg-indigo-500 disabled:bg-gray-400 text-white py-3 font-bold flex-1 rounded-lg hover:bg-black disabled:pointer-events-none;
}

.back-button {
  @apply py-3 font-brown-regular  text-sm leading-6 text-gray-500 flex-1;
}

.close-button {
  @apply top-1 right-1 absolute w-8 h-8 flex items-center justify-center;
}

.question-icon svg {
  @apply w-full h-full content-center;
}

.color-checked {
  @apply text-indigo-500;
}

.password-input {
  background: url(assets/EyeIcon.svg) no-repeat scroll center;
  background-position-x: calc(100% - 10px);
}
.upgrade-plan--BG,
.pre-made-plan--BG,
.custom-plan--BG {
  @apply relative z-0;
}

.upgrade-plan--BG {
  @apply bg-gradient-to-t from-[#5F1DA8] to-[#635CF3];
}
.upgrade-plan--BG:before,
.pre-made-plan--BG:before,
.custom-plan--BG:before {
  @apply absolute -z-10 bg-cover bg-no-repeat bg-center bg-contain w-full h-full top-0 right-0;
  content: "";
}

.pre-made-plan--BG:before {
  background-image: url(assets/PreMade.svg);
  background-size: 42%;
  background-position: 100% 45%;
}

.custom-plan--BG:before {
  background-image: url(assets/CustomMade.svg);
  background-size: 42%;
  background-position: 99% 70%;
}
/* end: copied from fintech-ui */

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 9px;
  border-radius: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbbbb;
  border-radius: 4px;
}

@font-face {
  font-family: "Brown-Bold";
  src: url("./assets/Brown-Bold.otf");
}
@font-face {
  font-family: "Brown-Regular";
  src: url("./assets/brown-regular.ttf");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/Inter-Regular.ttf");
}

.text-input {
  @apply appearance-none border-b-2 focus:border-primary-blue outline-none w-full py-2 placeholder:text-black;
}

.form-padding {
  @apply py-[51px] pl-[61px] pr-[61px];
}

.auth-card-padding {
  @apply py-12 px-14;
}

.slide-in-transition {
  animation: inAnimation 450ms ease-in-out;
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: 600px;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
    max-height: 600px;
  }
  100% {
    opacity: 0;
    max-height: 0px;
  }
}
