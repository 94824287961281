/* start: root styles */
.inputfield-root {
  @apply relative z-0;
}
/* end: root styles */

/* start: control styles */
.inputfield-control {
  @apply block py-2.5 px-0 w-full
    text-base text-gray-900
    bg-transparent border-0 border-b-2 border-gray-300
    appearance-none 
    focus:outline-none
    focus:ring-0
    focus:border-blue-600;
}
/* end: control styles */

/* start: fix autofill issues with floating labels */
.inputfield-control:autofill,
.inputfield-control:autofill:hover,
.inputfield-control:autofill:focus,
.inputfield-control:autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
.PhoneInput input:autofill,
.PhoneInput input:autofill:hover,
.PhoneInput input:autofill:focus,
.PhoneInput input:autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
/* end: fix autofill issues with floating labels */

/* start: label styles */
.inputfield-label {
  @apply absolute
    font-brown-regular text-sm text-black opacity-50
    duration-300
    transform -translate-y-6 scale-75
    top-3
    /* -z-10 */
    origin-[0]
    peer-focus:left-0
    peer-focus:text-black
    peer-focus:scale-75
    peer-focus:-translate-y-6
    peer-focus:opacity-100
    peer-autofill:scale-75
    peer-autofill:-translate-y-6
    peer-autofill:opacity-100
    peer-placeholder-shown:scale-100
    peer-placeholder-shown:translate-y-0;
}
/* end: label styles */

/* start: apply floating label rules for select field and other styling */
.select ~ .inputfield-label {
  @apply -z-10;
}

.select:has(.select-placeholder):not(:has(input[aria-expanded="true"])) ~ .inputfield-label {
  @apply translate-y-0 scale-100;
}

.select:has(input:focus) ~ .inputfield-label {
  @apply text-black;
}

.inputfield-root:has(.select):has(input[aria-expanded="true"]) {
  @apply z-10;
}

.select-control--active {
  @apply
  outline-none
  ring-0
  border-blue-600;
}

.select-control {
  @apply cursor-text
  focus:outline-none
  focus:ring-0
  focus:border-blue-600;
}
.select-dropdown-indicator {
  @apply cursor-pointer;
}

.select-menu {
  @apply z-10  bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-700;
}

.select-option {
  @apply text-gray-900 hover:bg-sky-700 hover:text-white select-none relative py-2 pl-4 pr-4 dark:text-gray-100;
}
.select-option--active {
  @apply bg-sky-700 text-white;
}

.selectfield-label {
  @apply font-brown-regular text-sm text-black/50 uppercase absolute top-4 left-5;
}
/* end: apply floating label rules for select field and other styling */

/* start: helper styles */
.inputfield-helper-text {
  @apply text-sm mt-1;
}
/* end: helper styles */

/* start: error state styles */
.inputfield-root.is-error > .inputfield-control,
.inputfield-root.is-error > .inputfield-label,
.inputfield-root.is-error > .inputfield-helper-text {
  @apply text-red-400;
}

.inputfield-root.is-error > .inputfield-control {
  @apply border-red-200;
}

.inputfield-root.is-error > .inputfield-control {
  @apply border-red-400;
}
/* end: error state styles */
.vi__wrapper {
  @apply w-full;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.code-verification--character__selected:empty::after {
  @apply  block absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-8 w-px bg-gray-500 animate-[blink_1s_infinite_step-end];
  content: "";
}

.code-verification--container {
  @apply w-full grid auto-cols-auto grid-flow-col gap-4 h-auto;
}

.code-verification--character {
  @apply rounded-2xl p-1 bg-white drop-shadow-sm ring  justify-center align-middle  ring-black/[.01] border-none cursor-text;
}

.code-verification--character-6 {
  @apply w-14 h-14;
}

.code-verification--character-5 {
  @apply w-16 h-16;
}

.code-verification--character__selected {
  @apply rounded-2xl p-1 bg-white drop-shadow-md ring ring-black/[.01] border-none;
}



